import { render, staticRenderFns } from "./operatingCare.vue?vue&type=template&id=2371fe76&scoped=true&"
import script from "./operatingCare.vue?vue&type=script&lang=js&"
export * from "./operatingCare.vue?vue&type=script&lang=js&"
import style0 from "./operatingCare.vue?vue&type=style&index=0&id=2371fe76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2371fe76",
  null
  
)

export default component.exports